<template>
  <div id="app">
    <main class="App__main">
      <transition name="fade" mode="out-in">
        <router-view />
      </transition>
    </main>

    <!-- <footer class="footer">
      <div class="container">
        <div class="six columns">
          <h6>© Dhruva Goyal</h6>
        </div>
        <div class="six columns">
          <h6 class="right">
            Made with
            <span class="red">♥</span>
            in India
          </h6>
        </div>
      </div>
    </footer> -->
  </div>
</template>

<script>
export default {
  name: "app",
};
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition-duration: 0.3s;
  transition-property: opacity;
  transition-timing-function: ease;
}

.fade-enter,
.fade-leave-active {
  opacity: 0;
}

</style>
