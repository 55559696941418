<template>
  <div class="container">
    <div class="projtitle">
      <h1 class="center med">MINET</h1>
    </div>

    <div class="flex-center">
      <splide class="splide-thing" :options="options">
        <splide-slide>
          <img loading="lazy" src="../assets/images/minet/1.jpg" alt="" />
        </splide-slide>

        <splide-slide>
          <img loading="lazy" src="../assets/images/minet/3.jpg" alt="" />
        </splide-slide>

        <splide-slide>
          <img loading="lazy" src="../assets/images/minet/4.jpg" alt="" />
        </splide-slide>

        <splide-slide>
          <img loading="lazy" src="../assets/images/minet/5.jpg" alt="" />
        </splide-slide>

        <splide-slide>
          <img loading="lazy" src="../assets/images/minet/6.jpg" alt="" />
        </splide-slide>
      </splide>
    </div>

    <br />
    <br />
    <br />

    <div class="row">
      <div class="seven columns">
        <br />
        <br />
        <br />
        <h5 class="med">MINET Club</h5>
        <p>
          MINET is the official technology club of The Mother's International
          School. As the President of this club, I am in charge of the club's
          main functionality – including participating in tech symposiums,
          making web applications, and organizing our annual event, MINET X.
        </p>
      </div>
      <div class="five columns">
        <div class="right">
          <img
            loading="lazy"
            style="width: 80%;"
            src="../assets/images/minet/logo.png"
            alt=""
          />
        </div>
      </div>
    </div>

    <br />
    <br />
  </div>
</template>

<script>
import { Splide, SplideSlide } from '@splidejs/vue-splide'

export default {
  name: 'Minet',
  components: {
    Splide,
    SplideSlide,
  },
  data() {
    return {
      options: {
        rewind: true,
        width: 800,
        gap: '1rem',
      },
    }
  },
}
</script>

<style scoped>
.projtitle {
  margin-bottom: 2em;
}
.splide-thing {
  max-width: 100% !important;
}
</style>
