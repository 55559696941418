<template>
  <div class="container">
    <div class="projtitle">
      <h1 class="center med">Ananda</h1>
    </div>

    <div class="vidpen">
      <iframe
        width="100%"
        src="https://www.youtube.com/embed/abr3D3DP8kg"
        frameborder="0"
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen
        class="video"
      ></iframe>
    </div>
    <br />
    <br />

    <div class="row">
      <div class="three columns">
        <img loading="lazy" src="../assets/images/ananda/1.jpg" alt="" />
      </div>
      <div class="nine columns">
        <br />
        <br />
        <p>
          These days, many people are stressed due to work, and the only people
          who can truly remain calm and relaxed are those who are physically and
          mentally fit. Not to mention in the progressive and evolving day and
          age, the average person finds minimal time to take care of their own
          self. That, combined with the insane workload and boatloads of stress
          that people have to tackle in their daily lives, can truly turn
          healthcare into less of a priority.
        </p>
        <p>
          As a result, public health is declining, and obesity is on the rise.
          Mental health is on the low and more and more people are falling prey
          to problems like anxiety and depression. To tackle all of the
          aforementioned, we bring to you - Ananda.
        </p>
        <p>
          Ananda is your personal yoga trainer. It corrects your posture in
          real-time while you exercise. It provides vocal feedback on your
          asanas and helps you practice yoga healthily and beneficially.
        </p>
      </div>
    </div>

    <br />
    <br />
    <br />

    <div class="row">
      <div class="seven columns">
        <h3 class="med">Features</h3>
        <p>
          <span class="med">Live Posture correction:</span>
          Ananda uses machine learning and artificial intelligence to correct
          your body posture while you're doing yoga
        </p>

        <p>
          <span class="med">Vocal Feedback:</span>
          Staring into your phone while practising would be a big pain, wouldn't
          it? Our app's voice assistant, Gayatri, will be your trainer.
        </p>

        <p>
          <span class="med">Curated Lessons:</span>
          We provide lessons based on your abilities. We have workouts ranging
          from easy asanas to difficult ones, for more advanced users.
        </p>

        <p>
          <span class="med">Learning Database:</span>
          Ananda learns about you with time. We try to provide lessons that are
          best suited for your abilities which advance at your pace.
        </p>
      </div>
      <div class="five columns">
        <br />
        <br />
        <img loading="lazy" src="../assets/images/projects/ananda.png" alt="" />
      </div>
    </div>

    <br />
    <br />
    <br />

    <div class="row">
      <div class="four columns">
        <img loading="lazy" src="../assets/images/ananda/2.jpg" alt="" />
      </div>
      <div class="eight columns">
        <br />
        <br />
        <h3 class="med">Why Ananda?</h3>
        <p>
          Incorporating yoga into your routine can help enhance your health,
          increase strength and flexibility and reduce symptoms of stress,
          depression, and it helps you increase your metabolism and lose weight.
          It also helps your cardiovascular system function better! Let Ananda
          guide you to complete mental and physical well being
        </p>

        <h3 class="med">Ananda+</h3>
        <p>
          For more serious enthusiasts, Ananda+ offers everything Ananda Free
          does, along with the added benefits of live sessions and instructors
          to help you achieve your fitness goal, much easier.
        </p>

        <br />
        <a href="https://ananda-app.com/">
          <button class="button-primary">Website</button>
        </a>
      </div>
    </div>

    <br />
    <br />
    <br />

    <br />
    <div class="flexing">
      <!-- <div class="flex-center"> -->
      <img loading="lazy" class="two columns" src="../assets/images/ananda/3.jpg" alt="" />
      <img loading="lazy" class="two columns" src="../assets/images/ananda/4.jpg" alt="" />
      <img loading="lazy" class="two columns" src="../assets/images/ananda/5.jpg" alt="" />
      <img loading="lazy" class="two columns" src="../assets/images/ananda/6.jpg" alt="" />
      <img loading="lazy" class="two columns" src="../assets/images/ananda/7.jpg" alt="" />
      <!-- </div> -->
    </div>
  </div>
</template>

<script>
export default {
  name: 'Ananda',
}
</script>

<style lang="scss" scoped>
.vidpen {
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 56.25%;
  margin-bottom: 4em;
}
.video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

button {
  margin-bottom: 4em;
}

a {
  border: none;
}

a:hover {
  border: none;
}

.flexing {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
</style>
