<template>
  <div class="container">
    <div class="vh">
      <h1 class="med">Blog</h1>
      <h5 class="center">
        Coming soon. Stay tuned!
				<br>
        <router-link to="/">Go back home.</router-link>
      </h5>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Minet',
}
</script>

<style scoped>
.vh {
  height: 92vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
</style>
