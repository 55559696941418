<template>
  <div class="container">
    <div class="projtitle">
      <h1 class="center med">Squad</h1>
    </div>
    <p>
      Squad is a 100% on-chain dApp that helps teams manage and share their
      files with a unique encryption layer between IPFS and their workflows,
      allowing for access control within different teams.
      <span style="font-weight: bold;"
        >This project was built by my team during EthIndia 2022 and won $5,000
        in bounties from IPFS, Protocol Labs and Polygon.</span
      >
    </p>
    <div class="center">
      <img
        loading="lazy"
        src="../assets/images/squad/mockup3.webp"
        style="width: 80%;"
        alt=""
      />
    </div>

    <div class="flex-center">
      <a
        href="https://github.com/Hackerbone/ethIndia22-cod3ine"
        target="_blank"
      >
        <button class="button-primary">
          Visit GitHub Repo
        </button>
      </a>
    </div>
    <h3 class="med">
      Problem: No existing or secure solution for companies to adopt Web3 for
      file management
    </h3>
    <p>
      In the world where Web3 and decentralisation is becoming mainstream, It is
      becoming more and more important to build proper org structures to manage
      different aspects of a DAO. Existing solutions for team management on Web3
      personal projects by kids that are not well developed, and are just built
      on top of ipfs making all the information available to everyone. Existing
      encryption solutions are weak and can not scale beyond managing one person
      which makes sense as these softwares are only useful for personal use.
    </p>

    <h3 class="med">Encryption Scheme</h3>
    <div class="row">
      <div class="six columns">
        <p>
          My main work in this project revolved around building out and testing
          the encryption scheme that made this product feasible and secure for
          an enterprise to deploy. Since there is nothing of this sort as of
          now, we came up with a unique encryption scheme(closely related to
          ECIES) combining asymmetric and symmetric encryption to allow only
          people in a particular group of an organisation to access each others
          files whereas other teams will be unable to access them(will not be
          able to decrypt them)
        </p>
      </div>
      <div class="six columns">
        <img
          loading="lazy"
          src="../assets/images/squad/encscheme.webp"
          alt=""
        />
      </div>
    </div>
    <h3 class="med">Edge Cases</h3>
    <p>
      One interesting case that was very challenging to tackle was when a new
      person is being added to a team. When the user joins, he would not have
      access to any file in the organisation as his public key will be new. We
      were able to tackle this by dynamically decrypting all aes keys by the
      admin user who adds people to the organisation and re encrypting them with
      the new public key and storing them on IPFS
    </p>

    <h3 class="med">Final Thoughts</h3>

    <p>
      Overall fun project, cryptography is always a interesting challenge be it
      in hackathons(building cryptography) or CTFs(breaking cryptography). I am
      still very bullish on Web3, it is a groundbreaking technology and very
      elegant. I will continue to support and build Web3 projects that promise
      true decentralisation(even if VCs won't).
    </p>
    <div class="row">
      <div class="six columns">
        <img loading="lazy" src="../assets/images/squad/mockup1.webp" alt="" />
      </div>
      <div class="six columns">
        <img loading="lazy" src="../assets/images/squad/mockup2.webp" alt="" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Squad",
};
</script>

<style lang="scss" scoped>
.vidpen {
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 56.25%;
  margin-bottom: 4em;
}
.video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

button {
  margin-bottom: 4em;
}
</style>
