<template>
  <div class="home">
    <div class="parallax">
      <img
        v-rellax="{ speed: -2 }"
        src="../assets/images/parallax/1.svg"
        class="circle one"
        loading="lazy"
        alt=""
      />
      <img
        v-rellax="{ speed: 3 }"
        src="../assets/images/parallax/2.svg"
        class="circle two"
        loading="lazy"
        alt=""
      />
      <img
        v-rellax="{ speed: 6 }"
        src="../assets/images/parallax/3.svg"
        class="circle three"
        loading="lazy"
        alt=""
      />
      <img
        v-rellax="{ speed: -4 }"
        src="../assets/images/parallax/4.svg"
        class="circle four"
        loading="lazy"
        alt=""
      />
      <img
        v-rellax="{ speed: -3 }"
        src="../assets/images/parallax/5.svg"
        class="circle five"
        loading="lazy"
        alt=""
      />
    </div>
    <div class="container">
      <section id="main">
        <div class="mainhome">
          <div class="ogtext">
            <h1>
              Hi, I'm
              <span class="med name">Dhruva</span>
            </h1>
            <div class="line"></div>
            <h6 class="m1 info">
              I am an entrepreneur, full-stack developer & OSCE3(OSCP, OSWE,
              OSED, OSEP) certified security researcher. I am currently building
              <a
                href="https://bugbase.ai"
                class="bugbase-cta"
                style="color: #10ca00 underline"
                >BugBase</a
              >
              full-time, revolutionising continuous security testing with bug
              bounty programs and AI powered autonomous penetration testing.
              BugBase is the trusted security partner for many companies and is
              backed by 2AMVC, Finsight Ventures and several C-level industry
              leaders
              <br />
              <br />
              I love to build, lead and grow teams. I like solving problems with
              code, and in my free time, I enjoy working on personal projects
              and experimenting with new tools and languages.
            </h6>
            <!-- <div class="links mainlinks"> -->
            <div class="gridlink">
              <div>
                <a href="/resume.pdf" target="_blank">Resume</a>
              </div>
              <div>
                <a href="https://dhruvagoyal.medium.com/">Blog</a>
              </div>
              <div>
                <a href="#work">Work</a>
              </div>
              <div>
                <a href="#projects">Projects</a>
              </div>
              <div>
                <a href="#contact">Contact</a>
              </div>
              <div>
                <a href="/terminal">Terminal</a>
              </div>
            </div>
          </div>
          <div class="right">
            <img
              src="../assets/dhruva1.jpg"
              loading="lazy"
              class="dhruva"
              alt=""
            />
          </div>
          <div class="u-cf"></div>
        </div>
      </section>
      <section id="work">
        <h2 class="med mhalf">Journey</h2>
        <p class="subheading2">the journey so far - figuring it out as I go</p>
        <div class="work">
          <div class="exp" style="margin-top: 3em;">
            <div class="image center">
              <img
                loading="lazy"
                src="@/assets/images/work/bugbase-logo.png"
                alt="oscp"
                style="width: 90%;"
              />
            </div>
            <div class="textcontent">
              <h3>BugBase</h3>
              <h5 class="pos">FOUNDER & CEO</h5>
              <p>
                Started BugBase out of my passion for cybersecurity, building
                products, and entrepreneurship. Did the whole startup crash
                course — built the product from the ground up, negotiated and
                landed million-dollar deals, raised funds, handled the legal
                side, managed sales and marketing, and gave a few technical
                talks along the way
              </p>
            </div>
          </div>
          <div class="exp rev">
            <div class="textcontent">
              <h3>OSCE<sup>3</sup></h3>
              <h5 class="pos">Certification</h5>
              <p>
                OSCE3 was no walk in the park, earned by completing OSWE, OSEP,
                and OSED, reflects advanced skills in red teaming, exploit
                development, and web application penetration testing,
              </p>
            </div>
            <div class="image center">
              <img
                loading="lazy"
                src="@/assets/images/work/OSCE3.png"
                alt="oscp"
                style="width: 50%;"
              />
            </div>
          </div>
          <div class="exp">
            <div class="image center">
              <img
                loading="lazy"
                src="@/assets/images/work/oscp.png"
                alt="oscp"
                style="width: 50%;"
              />
            </div>
            <div class="textcontent">
              <h3>OSCP</h3>
              <h5 class="pos">Certification</h5>
              <p>
                I kicked off my infosec journey with OSCP back in 2020 to set a
                foundation in hands-on penetration testing. It was my way of
                establishing a baseline and challenging myself right from the
                start.
              </p>
            </div>
          </div>

          <main class="App__main">
            <transition name="fade" mode="out-in">
              <div v-if="exp">
                <div class="exp rev">
                  <div class="textcontent">
                    <h3>BELS</h3>
                    <h5 class="pos">CTO</h5>
                    <p>
                      Started a telemedicine venture called BELS at the onset of
                      COVID, aiming to change how patients interact with
                      doctors. I handled the full-stack design and development
                      of the mobile app and the connected database.
                      Unfortunately, we couldn't scale it the way we hoped.
                    </p>
                  </div>
                  <div class="image">
                    <img
                      loading="lazy"
                      src="@/assets/images/bels/banner.jpg"
                      alt=""
                    />
                  </div>
                </div>

                <div class="exp">
                  <div class="image">
                    <img
                      loading="lazy"
                      src="@/assets/images/minet/minetsite.png"
                      alt=""
                    />
                  </div>
                  <div class="textcontent">
                    <h3>MINET</h3>
                    <h5 class="pos">President</h5>
                    <p>
                      MINET was my high school's tech club, and it played a huge
                      role in kickstarting my journey into tech and
                      entrepreneurship. I always mention it because it was my
                      first real taste of what I love to do. In my senior year,
                      I became the President, leading the team through various
                      competitions like robotics and hackathons.
                    </p>
                  </div>
                </div>

                <div class="exp">
                  <div class="textcontent qls">
                    <h3>QLS Academy</h3>
                    <h5 class="pos">Developer</h5>
                    <p>
                      I worked as a back-end web developer for QLS Academy - a
                      startup trying to evolve an immersive educational
                      experience. I worked on DRM encryption and python
                      scripting.
                    </p>
                  </div>
                  <div class="image center">
                    <img loading="lazy" src="@/assets/images/qls.png" alt="" />
                  </div>
                </div>
              </div>
            </transition>
          </main>
        </div>

        <h5 class="center viewbtn" v-on:click="exp = !exp">
          <span v-if="!exp">View More</span>
          <span v-if="exp">View Less</span>
        </h5>
      </section>

      <section id="projects">
        <h2 class="med mhalf">My Work</h2>
        <p class="subheading">
          some things i've made along the way - hackathons/work/fun
        </p>
        <div class="projects top">
          <router-link
            v-for="project in firstProjects"
            :key="project.id"
            :to="'/projects/' + project.link"
          >
            <div class="project">
              <img
                loading="lazy"
                :src="getImgUrl(project.image)"
                class="proj-img"
                v-bind:alt="project.name"
              />
              <h4>{{ project.name }}</h4>
              <div class="pills">
                <div class="pill" v-for="tag in project.tags">{{ tag }}</div>
              </div>
            </div>
          </router-link>
        </div>

        <main class="App__main">
          <transition name="fade" mode="out-in">
            <div v-if="projects">
              <div class="projects">
                <router-link
                  v-for="project in secondProjects"
                  :key="project.id"
                  :to="'/projects/' + project.link"
                >
                  <div class="project">
                    <img
                      loading="lazy"
                      :src="getImgUrl(project.image)"
                      class="proj-img"
                      v-bind:alt="project.name"
                    />
                    <h4>{{ project.name }}</h4>
                    <div class="pills">
                      <div class="pill" v-for="tag in project.tags">
                        {{ tag }}
                      </div>
                    </div>
                  </div>
                </router-link>
              </div>
            </div>
          </transition>
        </main>

        <h5 class="center viewbtn" v-on:click="projects = !projects">
          <span v-if="!projects">View More</span>
          <span v-if="projects">View Less</span>
        </h5>
      </section>

      <section id="contact" class="m2">
        <div class="row">
          <div class="flex-center">
            <div class="eight columns">
              <h2 class="med">Contact</h2>
              <h6 class="mhalf info">
                The best way to reach me is to email me at
                <span>goyal(dot)dhruva(at)gmail(dot)com</span> or
                <span>dhruva(at)bugbase(dot)ai</span>
                <span>.</span><br /><br />
                I will try to respond within 8-10 hours of receiving an
                interesting message. Alternatively, you could connect with me on
                social media.
              </h6>
            </div>
            <div class="contact" style="margin: 1em 0">
              <a href="https://github.com/shero4">GitHub</a>
              <a href="https://twitter.com/dhruvagoyal">Twitter</a>
              <a href="https://www.linkedin.com/in/dhruvagoyal/">Linkedin</a>
            </div>
          </div>
        </div>
        <div class="u-cf"></div>
      </section>
    </div>
  </div>
</template>

<script>
export default {
  name: "Home",

  components: {},
  mounted() {
    // $('#contact').hide()
  },
  methods: {
    getImgUrl(pic) {
      return require("../assets/images/projects/" + pic);
    },
  },
  computed: {
    firstProjects() {
      return this.work.filter((project) => project.id <= 6);
    },
    secondProjects() {
      return this.work.filter((project) => project.id > 6);
    },
  },
  data() {
    return {
      skills: false,
      projects: false,
      exp: false,
      work: [
        {
          id: 1,
          name: "BugBase",
          link: "bugbase",
          image: "bugbase.png",
          tags: ["cybersec"],
        },
        {
          id: 2,
          name: "Squad",
          link: "squad",
          image: "squad.webp",
          tags: ["web3", "crypto"],
        },
        {
          id: 3,
          name: "Pentest Copilot",
          link: "pentest-copilot",
          image: "copilot.png",
          tags: ["genai", "cybersec"],
        },
        {
          id: 4,
          name: "BELS",
          link: "bels",
          image: "bels.png",
          tags: ["telemedicine", "mobile"],
        },
        {
          id: 5,
          name: "Ananda",
          link: "ananda",
          image: "ananda.png",
          tags: ["ML", "mobile"],
        },
        {
          id: 6,
          name: "Ponder",
          link: "ponder",
          image: "ponder.png",
          tags: ["web", "mobile"],
        },
        {
          id: 7,
          name: "MINET",
          link: "minet",
          image: "minet2.png",
          tags: ["live", "web"],
        },
        {
          id: 8,
          name: "ExamTantra",
          link: "examtantra",
          image: "examtantra.png",
          tags: ["mobile"],
        },
        {
          id: 9,
          name: "Sahyog",
          link: "sahyog",
          image: "sahyog.png",
          tags: ["web"],
        },
        {
          id: 10,
          name: "Enigma",
          link: "enigma",
          image: "enigma.png",
          tags: ["web"],
        },
        {
          id: 11,
          name: "Shut.AI",
          link: "shutai",
          image: "shutai.png",
          tags: ["web"],
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
.bugbase-cta:hover {
  text-decoration: none;
  border-bottom: 1px solid #10ca00;
}

.proj-img {
  height: 12rem;
  width: auto;
}

.pills {
  display: flex;
  justify-content: space-between;
  gap: 0.5rem;
}
.pill {
  pointer-events: none;
  background-color: #eff4ff;
  border: none;
  color: #2557d6;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  border-radius: 100px;
  font-size: 12px;
  padding: 0.2rem 2rem;
  text-transform: uppercase;
}

.qls {
  margin-left: 1em;
}

.fade-enter-active,
.fade-leave-active {
  transition-duration: 0.5s;
  transition-property: opacity;
  transition-timing-function: ease;
}

.project {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1.2rem;
}

.fade-enter,
.fade-leave-active {
  opacity: 0;
}

.home {
  scroll-behavior: smooth;
}

#skills {
  margin-bottom: 10em;
}

.viewbtn {
  margin: 2em 0;

  span {
    color: #e71d36;
    border-bottom: solid 1px transparent;
    transition: 0.3s;
    font-size: 0.8em;
  }

  span:hover {
    color: #9c1726;
    border-color: #9c1726;
    cursor: pointer;
  }
}

// Parallax
.circle {
  position: absolute;
}

.circle.one {
  left: 7em;
  top: 8em;
  width: 7.5em;
}

.circle.two {
  right: 22em;
  bottom: 20em;
  width: 10em;
}

.circle.three {
  left: 4em;
  bottom: 12em;
  width: 7em;
}

.circle.four {
  right: 6em;
  top: 4em;
  width: 5em;
}

.circle.five {
  right: 10em;
  bottom: 7em;
  width: 6em;
}

@media (max-width: 750px) {
  .image.qls {
    margin: 0em !important;
  }
}

@media (max-width: 1025px) {
  .circle.one {
    left: 3em;
    top: 6em;
    width: 7.5em;
  }

  .circle.two {
    right: 10em;
    bottom: 20em;
    width: 10em;
  }

  .circle.three {
    left: 1em;
    bottom: 3em;
    width: 7em;
  }

  .circle.four {
    right: 6em;
    top: 4em;
    width: 5em;
  }

  .circle.five {
    right: 3em;
    bottom: 5em;
    width: 6em;
  }
}
</style>
