<template>
  <div class="container">
    <div class="projtitle">
      <h1 class="center med">BugBase</h1>
    </div>

    <div class="vidpen">
      <iframe
        title="product video"
        width="100%"
        src="https://www.youtube.com/embed/drkGmnCFrFI"
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen
        class="video"
      ></iframe>
    </div>
    <br />
    <div class="row">
      <h3 class="med">What is BugBase?</h3>
      <div class="twelve columns">
        <p>
          BugBase is a SaaS platform that conducts comprehensive and managed
          security programs replacing old age VAPT with result based and
          effective bug bounty programs to assist businesses worldwide in
          identifying, managing, and mitigating vulnerabilities.
        </p>
        <p>
          BugBase makes it straightforward for startups, SMEs and Enterprises to
          host bug bounty programs seamlessly by managing them end-to-end with
          our product. We believe that if we want a truly secure digital world,
          we must defend its foundation in every possible way. Therefore, we
          would collaborate with various private and public companies to ensure
          the safety of all applications.
        </p>
        <p>
          BugBase started out as a Hackathon passion project in 2021 and has now
          turned into my full time job
        </p>
      </div>
    </div>

    <br />
    <br />
    <div class="row">
      <div class="twelve columns">
        <img
          loading="lazy"
          src="../assets/images/bugbase/bugbase-design.png"
          alt=""
        />
      </div>
    </div>
    <br />
    <br />
    <div class="row lmao" style="align-items: center; display: flex;">
      <div class="seven columns">
        <h3 class="med">Building BugBase</h3>
        <p>
          <span class="med">Backend:</span>
          The backend has been built with NodeJS using the express framework. We
          store our user sessions in redis and large data on AWS S3 buckets. We
          are using a multi tenant infrastructure with manged MongoDB Atlas as
          our database.
        </p>

        <p>
          <span class="med">Frontend:</span>
          The Frontend is responsive and has been written in NextJS to ensure
          effective SEO with Server Side Rendering, it interacts with the
          backend using axios via a REST API.
        </p>

        <p>
          <span class="med">Deployment:</span>
          The CI/CD pipeline runs on Github Actions that builds docker
          containers, uploads them to AWS ECR, pulls and runs the containers on
          EC2 instances managed by ECS. Infra has been made into code with
          Terraform
        </p>

        <p>
          <span class="med">Hosting:</span>
          We use NGINX as our load balancer and AWS WAF to filter out and block
          bad requests or DDoS attempts. The backend runs on a reverse proxy at
          /api and the whole website is routed via cloudflare
        </p>
      </div>
      <div
        class="five columns"
        style="justify-content: center; align-items: center; display: flex; height: 100%;"
      >
        <img
          loading="lazy"
          style="width: 80%"
          src="../assets/images/projects/bugbase.png"
          alt=""
        />
      </div>
    </div>

    <br />
    <br />

    <div class="row">
      <div class="twelve columns">
        <h3 class="med">Updates / Progress Since Launch</h3>
        <p>
          I am currently working on growing and scaling BugBase as it's
          Co-Founder & CEO
        </p>
        <p>
          Update(2021): We have launched BugBase on 1st August 2021, you can
          check it out at
          <a href="https://bugbase.ai/" target="_blank">bugbase.ai</a>
        </p>
        <p>
          Update(2022): We have matured BugBase into a fully functional product
          and few pilots, rasied $500K in venture capital, established office in
          Bengaluru, India
        </p>
        <p>
          Update(2023): We are a successful and thriving business with over 30
          clients from USA, Dubai, India and Singapore. Established HQ in
          Singapore and USA.
        </p>
        <a href="https://bugbase.in/" target="_blank">
          <button class="button-primary">CHECK US OUT HERE</button>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "BugBase",
};
</script>

<style lang="scss" scoped>
@media screen and (max-width: 600px) {
  .lmao {
    flex-direction: column;
  }
}

.vidpen {
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 56.25%;
  margin-bottom: 4em;
}
.video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0px;
}

button {
  margin-bottom: 4em;
}

a {
  border: none;
}

a:hover {
  border: none;
}

.flexing {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
</style>
