<template>
  <div class="container">
    <div class="projtitle">
      <h1 class="center med">Shut.AI</h1>
      <h5 class="center">Reclaim your sleep.</h5>
    </div>

    <div class="vidpen">
      <iframe
        width="100%"
        src="https://www.youtube.com/embed/UGxAZfGfZc8"
        frameborder="0"
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen
        class="video"
      ></iframe>
    </div>
    <br />
    <br />

    <div class="row">
      <div class="six columns">
        <img loading="lazy" src="../assets/images/shutai/sleep.svg" alt="" />
      </div>
      <div class="six columns">
        <br />
        <br />
        <h5>The Problem</h5>
        <p>
          People sleep for a sufficient amount of time but don't get good
          quality of sleep, feeling tired through the day.
        </p>
        <h5>The Solution</h5>
        <p>
          Our app plays music while you sleep and recognizes how your body
          responds to it. It then plays songs that put you to deeper sleep and
          maintains REM throughout the night. Before your alarm, it plays "light
          sleep" songs in order to make waking up easier.
        </p>
      </div>
    </div>

    <br />
    <br />
    <br />

    <div class="flex-center">
      <a href="https://anishbajaj.com/shutai.html">
        <button class="button-primary">Website</button>
      </a>
    </div>

    <br />
    <a href="https://anishbajaj.com/shutai.html">
      <img loading="lazy" src="../assets/images/shutai/banner.png" alt="" />
    </a>
  </div>
</template>

<script>
export default {
  name: 'Shut.AI',
}
</script>

<style lang="scss" scoped>
.vidpen {
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 56.25%;
  margin-bottom: 4em;
}
.video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

button {
  margin-bottom: 4em;
}

a {
	border: none;
}

a:hover {
	border: none;
}
</style>
