<template>
  <div class="container">
    <div class="projtitle">
      <h1 class="center med">Ponder</h1>
    </div>

    <div class="vidpen">
      <iframe
        width="100%"
        src="https://www.youtube.com/embed/kIT2VO3YHn0"
        frameborder="0"
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen
        class="video"
      ></iframe>
    </div>

    <div class="flex-center">
      <button class="button-primary">Download the APK</button>
    </div>

    <p>
      Introducing Ponder, a tool to help creative professionals think better. We
      decided to take a different take on the Developer Tools track. Nowadays we
      see new tools come up for developers that try to improve the way they
      code/work or make it more efficient, but we don’t see tools to help them
      create new ideas.
    </p>

    <p>
      Being creative is one of the most important skills in the 21st century.
      The truth is that software development requires a lot of creativity. While
      coding doesn't usually involve complete freedom of expression in the same
      way that art and music do, the creativity involved with coding is
      technical creativity. Our mission is to help all creative
      professionals(not just programmers) come up with original ideas that they
      can implement into their projects.
    </p>

    <br />

    <div class="row">
      <div class="six columns">
        <img loading="lazy" src="../assets/images/focus.png" alt="" />
      </div>

      <div class="six columns">
        <img loading="lazy" src="../assets/images/curate.png" alt="" />
      </div>
    </div>

    <br />
    <br />

    <p>
      Music: Do you feel that you’re more productive while listing to music?
      You’re not alone. Studies have shown that people are much more creative
      while doing certain tasks and music is one of them. We have a library of
      our original tracks with our own album art in the lofi genre composed by
      my colleague Aditya Pramar to help our customers think better.
    </p>
  </div>
</template>

<script>
export default {
  name: 'Ponder',
}
</script>

<style lang="scss" scoped>
.vidpen {
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 56.25%;
  margin-bottom: 4em;
}
.video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

button {
  margin-bottom: 4em;
}
</style>
