<template>
  <div class="container">
    <div class="projtitle">
      <h1 class="center med">Pentest Copilot</h1>
    </div>
    <center>
      <b>
        <p>
          Pentest Copilot is an advanced, AI-driven tool designed to streamline
          and enhance ethical hacking engagements.
        </p>
      </b>
    </center>
    <div class="vidpen">
      <iframe
        title="product video"
        width="100%"
        src="https://www.youtube.com/embed/g7Wvq5boi74?si=XPj0nZbN1eFFisef"
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen
        class="video"
      ></iframe>
    </div>
    <br />
    <div class="row">
      <h3 class="med">What is Pentest Copilot?</h3>
      <div class="twelve columns">
        <p>
          Pentest Copilot is not just a tool but a comprehensive partner in the
          pentesting journey. From the initial reconnaissance to the final
          stages of data extraction and cleanup, it's designed to assist users
          through every phase. What sets it apart is its ability to understand
          and utilize context effectively.
        </p>
      </div>
    </div>

    <br />
    <br />
    <div class="row">
      <div class="twelve columns">
        <center>
          <img
            loading="lazy"
            src="../assets/images/copilot/copilot-design.png"
            alt=""
            style="width: 60%;"
          />
        </center>
      </div>
    </div>
    <br />
    <br />
    <div class="row lmao" style="align-items: center; display: flex;">
      <div class="seven columns">
        <h3 class="med">Problem we are solving</h3>
        <p>
          Our goal with Pentest Copilot was to streamline the complex pentesting
          process. By automating critical tasks like payload generation and
          command formulation, we're saving time and reducing errors.
        </p>
        <p>
          Pentest Copilot's targeted guidance system helps avoid unnecessary
          detours, keeping the focus on crucial vulnerabilities. Its
          platform-agnostic nature also ensures that it's accessible to a wider
          range of users, regardless of their operating system.
        </p>
      </div>
      <div
        class="five columns"
        style="justify-content: center; align-items: center; display: flex; height: 100%;"
      >
        <img
          loading="lazy"
          style="width: 60%"
          src="../assets/images/projects/copilot.png"
          alt=""
        />
      </div>
    </div>

    <br />
    <br />

    <div class="row">
      <div class="twelve columns">
        <h3 class="med">What is Up Next?</h3>
        <p>
          Looking ahead, we're evolving Pentest Copilot into a complete Red Team
          Automation solution. This includes integrating more data points and
          implementing real-time command validation for quicker, more accurate
          responses. We're keen on balancing AI autonomy with human expertise to
          ensure a nuanced pentesting approach.
        </p>
        <p>
          Continuous improvement is at the heart of Pentest Copilot, as we aim
          to maintain its position at the forefront of ethical hacking
          technology.
        </p>
        <a href="https://copilot.bugbase.ai/" target="_blank">
          <button class="button-primary" style="margin-right: 10px;">
            CHECK US OUT HERE
          </button>
        </a>
        <a
          href="https://www.producthunt.com/products/pentest-copilot-by-bugbase#pentest-copilot-by-bugbase"
          target="_blank"
        >
          <button class="button-primary">PRODUCT HUNT LAUNCH</button>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Copilot",
};
</script>

<style lang="scss" scoped>
@media screen and (max-width: 600px) {
  .lmao {
    flex-direction: column;
  }
}

.vidpen {
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 56.25%;
  margin-bottom: 4em;
}

.video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0px;
}

button {
  margin-bottom: 4em;
}

a {
  border: none;
}

a:hover {
  border: none;
}

.flexing {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
</style>
