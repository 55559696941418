<template>
  <div>
    <div class="container">
      <nav>
        <div class="leftstuff">
          <a href="/">
            <h5>Dhruva Goyal</h5>
          </a>
        </div>
        <div class="rightstuff">
          <a href="/#projects">Projects</a>
          <a href="/#work">Work</a>
          <a href="/#contact">Contact</a>
        </div>
      </nav>
    </div>
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: "Projects",
};
</script>

<style lang="scss" scoped>
nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1em 0;
}

.rightstuff {
  margin-bottom: -0.75em;
  a {
    margin-left: 1em;
  }
}
</style>
