<template>
  <div class="container">
    <div class="projtitle">
      <h1 class="center med">Enigma</h1>
    </div>

    <img loading="lazy" src="../assets/images/enigma/login.png" alt="" />
    <br />
    <br />

    <div class="flex-center">
      <div class="eight columns">
        <p>
          As the Member and Technical Head of my school's Physics Club, I
          organized its annual event, Infinity, which is an annual intra-school
          event comprising of four sub events that are organized for Classes 9
          to 12.
        </p>
      </div>
    </div>

    <br />

    <div class="flex-center">
      <div class="card six columns">
        <h5 class="med">Events</h5>
        <ul>
          <li>Quiz</li>
          <li>Theory & Experimentation</li>
          <li>Fermi – Test of Approximation</li>
          <li>Enigma – Online Physics Hunt</li>
        </ul>
      </div>
    </div>

    <br />
    <br />

    <div class="flex-center">
      <div class="eight columns">
        <p>
          I designed the website
          <a href="//teamimpulse.co">teamimpulse.co</a>
          & made the web application for the online cryptic hunt using NodeJS
        </p>
      </div>
    </div>
    <div class="flex-center">
      <div class="six columns">
        <img loading="lazy" src="../assets/images/projects/enigma.png" alt="" />
      </div>
    </div>
    <br />
    <br />
  </div>
</template>

<script>
export default {
  name: 'Enigma',
}
</script>

<style lang="scss" scoped>
.vidpen {
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 56.25%;
  margin-bottom: 4em;
}
.video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

button {
  margin-bottom: 4em;
}

.card {
  background-color: #eaeaea;
  padding: 1.5em 2em 2em 2em;
  ul {
    margin: 0;
    li {
      margin: 0;
    }
  }
}
</style>
