import Vue from "vue";
import VueRouter from "vue-router";
import Home from "@/views/Home.vue";
import About from "@/views/About.vue";
import Projects from "@/views/Projects.vue";

import Minet from "@/views/Minet.vue";
import Ananda from "@/views/Ananda.vue";
import Bels from "@/views/Bels.vue";
import Enigma from "@/views/Enigma.vue";
import Ponder from "@/views/Ponder.vue";
import Shutai from "@/views/Shutai.vue";
import Soon from "@/views/Soon.vue";
import NotFound from "@/views/404.vue";
import BugBase from "@/views/BugBase.vue";
import Squad from "@/views/Squad.vue";
import Copilot from "@/views/Copilot.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/about",
    name: "About",
    component: About,
  },
  {
    path: "/blog",
    name: "Blog",
    component: Soon,
  },
  {
    path: "/projects",
    component: Projects,
    children: [
      { path: "/projects/minet", component: Minet },
      { path: "/projects/ananda", component: Ananda },
      { path: "/projects/ponder", component: Ponder },
      { path: "/projects/bels", component: Bels },
      { path: "/projects/enigma", component: Enigma },
      { path: "/projects/shutai", component: Shutai },
      { path: "/projects/bugbase", component: BugBase },
      { path: "/projects/squad", component: Squad },
      { path: "/projects/pentest-copilot", component: Copilot },
    ],
  },
  { path: "*", component: NotFound },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,

  scrollBehavior(to, from, savedPosition) {
    if (to.hash) {
      return window.scrollTo({
        top: document.querySelector(to.hash).offsetTop,
        behavior: "smooth",
      });
      return document
        .querySelector(to.hash)
        .scrollIntoView({ behavior: "smooth" });
    } else {
      return { x: 0, y: 0 };
    }
  },
});

export default router;
