import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import VueSplide from "@splidejs/vue-splide";
import VueRellax from "vue-rellax";

import "@splidejs/splide/dist/css/themes/splide-skyblue.min.css";
import "./assets/css/skeleton.css";
import "./assets/css/global.scss";

Vue.use(VueRellax);
Vue.use(VueSplide);
Vue.config.productionTip = false;

new Vue({
  router,
  render: (h) => {
    return h(App);
  },
}).$mount("#app");
