<template>
  <div class="container">
    <div class="vh">
      <h1>404</h1>
      <h5>
        Page not found. You can go back
        <router-link to="/">Home</router-link>
        <span>.</span>
      </h5>
    </div>
  </div>
</template>

<script>
export default {
  name: "Minet",
};
</script>

<style scoped>
.vh {
  height: 92vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
</style>
