<template>
  <div class="container">
    <div class="projtitle">
      <h1 class="center med">BELS</h1>
      <h5 class="center">Telemedicine</h5>
    </div>

    <img loading="lazy" src="../assets/images/bels/promo.jpg" alt="" />
    <br />
    <br />
    <br />
    <br />
    <br />

    <div class="flex-center">
      <a href="https://play.google.com/store/apps/details?id=io.bels.app">
        <button class="button-primary">
          Get the App
        </button>
      </a>
    </div>

    <br />
    <br />
    <br />
    <div class="row">
      <div class="three columns">
        <img loading="lazy" src="../assets/images/bels/1.jpg" alt="" />
      </div>
      <div class="nine columns">
        <br />
        <br />
        <p>
          BELS is an Indian telemedicine venture revolutionizing the way doctors
          interact with their patients. Our app is divided into three separate
          sections: Diagnostics - where you can book diagnostic tests with
          prominent labs for home collection or book a visitation date, Medicine
          - where you can purchase medicines that will be delivered to your
          house and Doctors - where you can have virtual appointments with
          highly qualified doctors in India.
        </p>
        <p>
          We provide an easy to navigate and ad-free interface for all our
          users. Right after your appointment is over - the doctor is presented
          with a screen to fill out a prescription. After the user receives the
          prescription, they can choose to directly purchase the medicine from
          our store or buy them on their own. The user can also book diagnostic
          tests via the app who's result will be directly sent to the doctor as
          soon as they are out.
        </p>
      </div>
    </div>

    <br />
    <br />
    <br />
    <br />

    <div class="row">
      <div class="six columns">
        <h3 class="med">Why BELS?</h3>
        <p>
          At BELS, we have also implemented a wallet which ensures that the
          doctors get the payment quickly and without hassle, unlike many other
          platforms. BELS does not charge a fee on payments, and the complete
          fee goes directly to the doctor. We also provide a 10-minute free
          interaction with every single doctor for you to decide If the doctor
          can actually help you out. BELS ensures that your data is completely
          secure and only available to you, including conversations with the
          doctor and reports.
        </p>
      </div>
      <div class="six columns">
        <img loading="lazy" src="../assets/images/bels/banner.jpg" alt="" />
      </div>
    </div>

    <br />
    <br />
    <br />
    <br />

    <br />
    <div class="flexing">
      <!-- <div class="flex-center"> -->
      <img loading="lazy" class="three columns" src="../assets/images/bels/1.jpg" alt="" />
      <img loading="lazy" class="three columns" src="../assets/images/bels/2.jpg" alt="" />
      <img loading="lazy" class="three columns" src="../assets/images/bels/3.jpg" alt="" />
      <img loading="lazy" class="three columns" src="../assets/images/bels/4.jpg" alt="" />
      <!-- </div> -->
    </div>
    <br />
    <br />
  </div>
</template>

<script>
export default {
  name: 'BELS',
}
</script>

<style lang="scss" scoped>
.vidpen {
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 56.25%;
  margin-bottom: 4em;
}
.video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

button {
  margin-bottom: 4em;
}

a {
  border: none;
}

a:hover {
  border: none;
}

.flexing {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
</style>
